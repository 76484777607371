import styles from "./SilenceModal.module.css"
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setCloseSilenceRules, setEditRule, setSilenceRules} from "../../store/reducers/silenceRulesReducer";
import AlertService from "../../services/AlertService";
import {setModalError} from "../../store/reducers/modalReducer";
import ReactDOM from "react-dom";
import ReactLoading from "react-loading";
import {SILENCED_RULES_DISPLAY} from "../../store/actions/DISPLAY_ACTIONS";

function SilenceModal() {
    const rulesPortal = document.getElementById("rulesPortal");
    const dispatch = useDispatch();
    const isOpenSilenceModal = useSelector(state => state.setSilenceRules.isOpen)
    const silenceRule = useSelector(state => state.setSilenceRules.editRule)
    const displayMode = useSelector(state => state.setDisplay.display)
    const [ruleProject, setRuleProject] = useState("")
    const [ruleHost, setRuleHost] = useState("")
    const [ruleAlert, setRuleAlert] = useState("")
    const [ruleDuration, setRuleDuration] = useState("")
    const [ruleComment, setRuleComment] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [position, setPosition] = useState({
        x: (window.innerWidth - 500) / 2,
        y: (window.innerHeight - 600) / 2
    });
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    useEffect(() => {
        setRuleProject(silenceRule.project)
        setRuleHost(silenceRule.host)
        setRuleAlert(silenceRule.alertName)
        setRuleDuration(silenceRule.duration)
        setRuleComment(silenceRule.comment)
    },[silenceRule])

    const onMouseDown = (e) => {
        if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA" || e.target.tagName === "BUTTON") {
            return;
        }
        setIsDragging(true);
        setOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const onMouseMove = (e) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - offset.x,
                y: e.clientY - offset.y
            });
        }
    };

    const onMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);

        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };
    }, [isDragging]);


    const refreshRules = async () => {
        const newRules = []
        try {
            const response = await AlertService.getSileneced()
            response.data.forEach((rule) => {
                newRules.push(rule)
            })
        }
        catch (e) {
            dispatch(setModalError("Oops. Something went wrong. Please, try a bit later"))
        }
        dispatch(setSilenceRules(newRules))
    }

    const emptyRule = {
        _id: null,
        project: "",
        host: "",
        alertName: "",
        endSilence: null,
        comment: "",
    }

    const onCloseHandler = () => {
        dispatch(setEditRule(emptyRule))
        dispatch(setCloseSilenceRules())
        setPosition({
            x: (window.innerWidth - 500) / 2,
            y: (window.innerHeight - 600) / 2
        })
    }

    const closeByEsc = (e) => {
        if (e.key === 'Escape') {
            onCloseHandler()
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", closeByEsc)
        return () => {
            document.removeEventListener("keydown", closeByEsc)
        }
    },[isOpenSilenceModal]);

    const onSaveRuleHandler = async () => {
        if (silenceRule._id) {
            setIsLoading(true)
            try {
                await AlertService.unsilence([{silenceId: silenceRule._id}])
            }
            catch (error) {
                setIsLoading(false)
                dispatch(setCloseSilenceRules())
                dispatch(setModalError("Oops. Something went wrong. Please, try a bit later"))
            }
        }
        let silenceTime
        if (ruleDuration) {
            silenceTime = Date.now() + Number(ruleDuration) * 60000
        } else {
            silenceTime = null
        }

        const newRule = {
            project: ruleProject,
            host: ruleHost,
            alertName: ruleAlert,
            startSilence: Date.now(),
            endSilence: silenceTime,
            comment: ruleComment,
        }

        try {
            await AlertService.silence(newRule)
        }
        catch (error) {
            setIsLoading(false)
            dispatch(setCloseSilenceRules())
            dispatch(setModalError("Oops. Something went wrong. Please, try a bit later"))
        }

        if(displayMode === SILENCED_RULES_DISPLAY) {
            refreshRules()
        }

        dispatch(setEditRule(emptyRule))
        dispatch(setCloseSilenceRules())
        setIsLoading(false)
    }


    if (!rulesPortal) {
        return null
    }

    if(!isOpenSilenceModal) {
        return null
    }

    return ReactDOM.createPortal( (
        <div className={styles.modalOverlay}>
        <div className={styles.modalWindow}
             style={{
                 position: "absolute",
                 left: `${position.x}px`,
                 top: `${position.y}px`,
                 cursor: isDragging ? "grabbing" : "grab"
             }}
             onMouseDown={onMouseDown}
        >
            <button className={styles.closeButton}
            onClick={(e) => {
                e.preventDefault()
                onCloseHandler()
            }}
            />
            <form className={styles.modalForm} >
                <input type="text" placeholder="Project" className={styles.inputField} value={ruleProject} onChange={(e) => setRuleProject(e.target.value)} />
                <input type="text" placeholder="Host" className={styles.inputField} value={ruleHost} onChange={(e) => setRuleHost(e.target.value)} />
                <input type="text" placeholder="Alert Name" className={styles.inputField} value={ruleAlert} onChange={(e) => setRuleAlert(e.target.value)} />
                <input type="text" placeholder="Duration min." className={styles.inputField} value={ruleDuration} onChange={(e) => setRuleDuration(e.target.value)} />
                <input type="text" placeholder="Comment" className={styles.inputField} value={ruleComment} onChange={(e) => setRuleComment(e.target.value)} />
                <button type="submit" className={`${ruleComment && ruleComment.length > 3 ? styles.editButton : styles.ediButtonDisabled}`}
                disabled={!ruleComment}
                        onClick={(e) => {
                            e.preventDefault();
                            onSaveRuleHandler()
                        }}
                >Save</button>
            </form>
        </div>
            {
                isLoading
                    ?
                    <div className={styles.loadOverlay}>
                        <ReactLoading color={'#01A2D8'} type={"spin"} height={200} width={100}/>
                    </div>
                    :
                    <></>
            }
    </div>
    ), rulesPortal)
}

export default SilenceModal