import {useDispatch, useSelector} from "react-redux";
import style from "./SilenceRules.module.css"
import AlertService from "../../services/AlertService";
import {setModalError} from "../../store/reducers/modalReducer";
import {sha256} from "js-sha256";
import {setEditRule, setOpenSilenceRules, setSilenceRules} from "../../store/reducers/silenceRulesReducer";

function SilenceRules(){
    const dispatch = useDispatch();
    const silenceRules = useSelector(state => state.setSilenceRules.rules)

    const refreshRules = async () => {
        const newRules = []
        try {
            const response = await AlertService.getSileneced()
            response.data.forEach((rule) => {
                newRules.push(rule)
            })
        }
        catch (e) {
            dispatch(setModalError("Oops. Something went wrong. Please, try a bit later"))
        }
        dispatch(setSilenceRules(newRules))
    }

    const onDeleteAlertButton = async (id) => {
        try {
             await AlertService.unsilence([{silenceId: id}])
        }
        catch (e) {
            dispatch(setModalError("Oops. Something went wrong. Please, try a bit later"))
        }

        refreshRules()

    }

    const onEditHandler = (id, project, host, alert, duration, comment) => {
        const editRule = {
            _id: id,
            project: project,
            host: host,
            alertName: alert,
            duration: duration,
            comment: comment,
        }

        dispatch(setEditRule(editRule))

        dispatch(setOpenSilenceRules())
    }

    return (
        <div className={style.page}>
            <table className={style.table}>
                <thead className={style.header}>
                    <tr>
                        <th scope="col" className={`${style.headerElement} ${style.authorColumn}`}>Author</th>
                        <th scope="col" className={`${style.headerElement} ${style.column}`}>Project</th>
                        <th scope="col" className={`${style.headerElement} ${style.column}`}>Host</th>
                        <th scope="col" className={`${style.headerElement} ${style.alertColumn}`}>Alert Name</th>
                        <th scope="col" className={`${style.headerElement} ${style.column}`}>Till</th>
                        <th scope="col" className={style.headerElement}>Comment</th>
                        <th scope="col" className={`${style.headerElement} ${style.actionsColumn}`}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {   silenceRules && silenceRules.length > 0 ?
                   ( silenceRules.map((rule) => (
                        <tr key={rule._id}>
                            <td className={`${style.authorColumn} ${style.cell}`}>
                                <div className={style.flexCell}>
                                    <img className={style.authorIcon} src={`https://gravatar.com/avatar/${sha256(rule.author)}?s=150)`} alt={rule.author} />
                                </div>
                            </td>
                            <td className={style.cell}>
                                <p className={style.textCell}>{rule.project ? rule.project : "*"}</p>
                            </td>
                            <td className={style.cell}>
                                <p className={style.textCell}>{rule.host ? rule.host : "*"}</p>
                            </td>
                            <td className={style.cell}>
                                <p className={style.textCell}>{rule.alertName ? rule.alertName : "*"}</p>
                            </td>
                            <td className={style.cell}>
                                <p className={style.textCell}>{rule.endSilence !== null
                                    ? new Date(parseInt(rule.endSilence, 10)).toLocaleString()
                                    : "permanent"
                                }</p>
                            </td>
                            <td className={style.cell}>
                                <p className={style.textCell}>{rule.comment}</p>
                            </td>
                            <td className={style.cell}>
                                <div className={style.flexCell}>
                                    <button className={style.button}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        let duration
                                        if (rule.endSilence !== null) {
                                            duration = Math.floor((rule.endSilence - Date.now()) / 60000)
                                        } else {
                                            duration = null
                                        }
                                        onEditHandler( rule._id, rule.project, rule.host, rule.alertName, duration, rule.comment)
                                    }}
                                    >
                                        Edit
                                    </button>
                                    <button className={style.button}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                onDeleteAlertButton(rule._id)
                                            }}>
                                        Delete
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )))
                    : null
                }
                </tbody>
            </table>
        </div>
    )
}

export default SilenceRules;


