import {sha256} from "js-sha256";
import {endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, startOfYear, subWeeks} from "date-fns";
import dayjs from "dayjs";
import {useSearchParams} from "react-router-dom";

const severityWeights = {
    UNKNOWN: 5,
    INFO: 4,
    WARNING: 3,
    CRITICAL: 2,
    EMERGENCY: 1
}

export function processTimeStamp (unixDateString) {
    const unixTime = parseInt(unixDateString, 10);
    const dateObject = new Date(unixTime * 1000);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2,'0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function processDuration(unixDateString) {
    const unixTime = parseInt(unixDateString, 10)
    const startDate = new Date(unixTime*1000);
    const currentDate = new Date();
    const timeDelta = currentDate - startDate;

    const days = Math.floor(timeDelta / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDelta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDelta % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}d ${hours}h ${minutes}m`
}

export function processAlertComment (word, commentReplaceRules) {
    for (const [key, value] of Object.entries(commentReplaceRules)){
        const regex = new RegExp(key, "i")
        const match = word.match(regex)
        if (word.match(regex)){
            const link = value.replace(/\$1/g,encodeURIComponent(match[0]))
            return (
                <a style={{display: "contents"}} target="_blank" rel="noopener noreferrer" href={link} > {word} </a>
            )
        }
    }
    return word
}

export function groupByField(alerts, fieldName){
    const groupedByField = alerts.reduce((acc, item) => {
        acc[item[fieldName]] = acc[item[fieldName]] || []
        acc[item[fieldName]].push(item)
        return acc
    }, {})

    return Object.values(groupedByField).filter(group => group.length > 1)
}

export function sortList (list, field, direction) {
    return list.sort((a, b) => {
        let aValue = a[field];
        let bValue = b[field];

        if (field === "severity") {
            aValue = severityWeights[aValue.toUpperCase()] ?? 5;
            bValue = severityWeights[bValue.toUpperCase()] ?? 5;
        }

        if (aValue < bValue) {
            return direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return direction === 'asc' ? 1 : -1;
        }
        return 0;
    });
}

export function stringToDate (string) {
        const date = new Date(string);
        return isNaN(date.getTime()) ? string : date;
}

export function prepareUser (data) {
    const user_raw = {
        userName: data.name,
        userId: data._id,
        userEmail: data.email,
        userImage: `https://gravatar.com/avatar/${sha256(data.email)}?s=150`,
        userPhone: data.phone ? data.phone : null,
        usersCommentReplaceRules: data.commentReplaceRules ? data.commentReplaceRules : null,
        userProjects: data.projects
    }
    const user = {...user_raw, userProjects: ['All', ...user_raw.userProjects]}
    return user
}

export function processTimeRange(selectedTimeRange, setStatsStart, setStatsEnd) {
    switch (selectedTimeRange.toLowerCase()) {
        case "last 24h":
            setStatsStart(new Date(Date.now() - 24 * 60 * 60 * 1000));
            setStatsEnd(new Date(Date.now()));
            break;
        case "today":
            setStatsStart(startOfDay(new Date()));
            setStatsEnd(new Date());
            break;
        case "yesterday":
            setStatsStart(startOfDay(new Date(Date.now() - 24 * 60 * 60 * 1000)));
            setStatsEnd(endOfDay(new Date(Date.now() - 24 * 60 * 60 * 1000)));
            break;
        case "this week":
            setStatsStart(startOfWeek(new Date(), { weekStartsOn: 1 }));
            setStatsEnd(new Date());
            break;
        case "last week":
            const lastWeek = subWeeks(new Date(), 1);
            setStatsStart(startOfWeek(lastWeek, { weekStartsOn: 1 }));
            setStatsEnd(endOfWeek(lastWeek, { weekStartsOn: 1 }));
            break;
        case "this month":
            setStatsStart(startOfMonth(new Date()));
            setStatsEnd(new Date());
            break;
        case "last month":
            const lastMonth = new Date();
            lastMonth.setMonth(lastMonth.getMonth() - 1);
            setStatsStart(startOfMonth(lastMonth));
            setStatsEnd(endOfMonth(lastMonth));
            break;
        case "this year":
            setStatsStart(startOfYear(new Date()));
            setStatsEnd(new Date());
            break;
        default:
            setStatsStart(new Date(Date.now() - 24 * 60 * 60 * 1000));
            setStatsEnd(new Date());
    }
}

export function parseDateString (dateString, fallback) {
    if (!dateString) {
        return fallback;
    }
    if (!isNaN(Number(dateString))) {
        return new Date(Number(dateString)*1000);
    }

    const parts = dateString.split(" ");
    let dateTimeStr = parts[0] + ' ' + (parts[1] || '');

    const parsedDate = dayjs(dateTimeStr, 'YYYY-MM-DD HH:mm:ss');
    return parsedDate.isValid() ? parsedDate.toDate() : fallback;

}