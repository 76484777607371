import {
    CLOSE_SILENCE_MODAL, EDIT_RULE,
    OPEN_SILENCE_MODAL,
    SHOW_SILENCED_ALERTS,
    SILENCED_RULES
} from "../actions/SILENCED_RULES";

const defaultState = {
    rules : [],
    editRule: {
        _id: null,
        project: "",
        host: "",
        alertName: "",
        duration: 0,
        comment: "",
    },
    isOpen: false,
    displaySilencedAlerts: false
}

export const silenceRulesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SILENCED_RULES:
            return {...state, rules:action.payload}
        case EDIT_RULE:
            return {...state, editRule:action.payload}
        case OPEN_SILENCE_MODAL:
            return {...state, isOpen: true}
        case CLOSE_SILENCE_MODAL:
            return {...state, isOpen: false}
        case SHOW_SILENCED_ALERTS:
            return {...state, displaySilencedAlerts: action.payload}
        default:
            return state
    }
}

export const setSilenceRules = (rules) => ({
    type: SILENCED_RULES,
    payload: rules
})
export const setEditRule = (rule) => ({
    type: EDIT_RULE,
    payload: rule
})
export const setOpenSilenceRules = (openSilenceRules) => ({
    type: OPEN_SILENCE_MODAL,
})
export const setCloseSilenceRules = (closeSilenceRules) => ({
    type: CLOSE_SILENCE_MODAL,
})

export const showSilencedAlerts = (status) => ({
    type: SHOW_SILENCED_ALERTS,
    payload: status
})