import {Link} from 'react-router-dom'
import styles from './SideBarMenu.module.css'
import {useDispatch, useSelector} from "react-redux";
import {
    ESCALATIONS_DISPLAY,
    HISTORY_DISPLAY,
    MAIN_DISPLAY,
    SILENCED_DISPLAY,
    STATS_DISPLAY,
    SILENCED_RULES_DISPLAY
} from "../../store/actions/DISPLAY_ACTIONS";
import {setSilenceRules} from "../../store/reducers/silenceRulesReducer";
import {setModalError} from "../../store/reducers/modalReducer";
import AlertService from "../../services/AlertService";


const SideBarMenu = () => {
    const dispatch = useDispatch()
    const isOpenedSideBar = useSelector(state => state.hiddenMenu.isOpenedSideBar)
    const activeMenuItem = useSelector(state => state.setDisplay.display)


    const getSilenceRules = async () => {
        const rules = []
        try {
            const response = await AlertService.getSileneced()
            response.data.forEach((rule) => {
                rules.push(rule)
            })
        }
        catch (e) {
            dispatch(setModalError("Oops. Something went wrong. Please, try a bit later"))
        }
        dispatch(setSilenceRules(rules))
    }

    return (
        <div className={isOpenedSideBar ? `${styles.menu}` : `${styles.menu} ${styles.menu_closed}`}>
            <ul className={styles.menuList}>
                <li key="alerts" className={styles.menuItem}>
                    <Link className={activeMenuItem === MAIN_DISPLAY ? `${styles.menuButton} ${styles.menuButtonActive}` : `${styles.menuButton}`}
                        to="/main"
                        onClick={e => {
                    }}> Alerts </Link>
                </li>
                <li key="silenced" className={styles.menuItem}>
                    <Link className={activeMenuItem === SILENCED_DISPLAY ? `${styles.menuButton} ${styles.menuButtonActive}` : `${styles.menuButton}`}
                        to="/silence_alerts"
                        onClick={e => {
                    }}> Silenced Alerts</Link>
                </li>
                <li key="silence rules" className={styles.menuItem}>
                    <Link className={activeMenuItem === SILENCED_RULES_DISPLAY ? `${styles.menuButton} ${styles.menuButtonActive}` : `${styles.menuButton}`}
                        to="/silence_rules"
                        onClick={e => {
                        getSilenceRules()
                    }}> Silence Rules </Link>
                </li>
                <li key="history" className={styles.menuItem}>
                    <Link className={activeMenuItem === HISTORY_DISPLAY ? `${styles.menuButton} ${styles.menuButtonActive}` : `${styles.menuButton}`}
                        to={"/history"}
                        onClick={e => {
                    }}> History </Link>
                </li>
                <li key="stats" className={styles.menuItem}>
                    <Link
                        className={activeMenuItem === STATS_DISPLAY ? `${styles.menuButton} ${styles.menuButtonActive}` : `${styles.menuButton}`}
                        to="/stat"
                        onClick={e => {
                    }}> Stats </Link>
                </li>
                <li key="escalations" className={styles.menuItem}>
                    <Link className={activeMenuItem === ESCALATIONS_DISPLAY ? `${styles.menuButton} ${styles.menuButtonActive}` : `${styles.menuButton}`}
                        to="/escalations"
                        onClick={e => {
                    }}> Escalations </Link>
                </li>
            </ul>
        </div>
    );
};

export default SideBarMenu;