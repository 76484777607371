import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './Alert.module.css'
import commonStyles from '../../styles/common.module.css'
import {processAlertComment, processTimeStamp} from "../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {deselectAlert, selectAlert} from "../../store/reducers/alertReducer";
import { setModalError, openModal} from "../../store/reducers/modalReducer";
import {sha256} from "js-sha256";
import AlertService from "../../services/AlertService";
import {HISTORY_DISPLAY, MAIN_DISPLAY, SILENCED_DISPLAY} from "../../store/actions/DISPLAY_ACTIONS";
import {Link, useSearchParams} from "react-router-dom";
import {RECHECKABLE_INPUT_PLUGIN} from "../../utils/vars";

const Alert = ({alert}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch()
    const isInspectMode = useSelector(state => state.setHeaderMenuItemValue.inspectMode)
    const userEmail = useSelector(state => state.authReducer.user.userEmail)
    const displayMode = useSelector(state => state.setDisplay.display)
    const durationRef = useRef(null)
    const commentRef = useRef(null)
    const recheckedAlerts = useSelector(state => state.webSocket.recheckAlerts)
    const user = useSelector( state => state.authReducer.user)
    const selectedAlerts = useSelector(state => state.setAlertReducer.selectedAlerts)

    const [isEnabledSilenceWindow, setEnabledSilenceWindow] = useState(false)
    const [isEnabledSilenceButton, setEnabledSilenceButton] = useState(false)
    const [silenceDuration, setSilenceDuration] = useState(undefined)
    const [silenceComment, setSilenceComment] = useState(null)
    const [isRefresh, setIsRefresh] = useState(false)
    const [failedRecheck, setFailedRecheck] = useState(false)
    const [fixInstruction, setFixInstruction] = useState('')
    const [processedString, setProcessedString] = useState('')
    const [alertComment, setAlertComment] = useState(null)

    const isRecheckableAlert = RECHECKABLE_INPUT_PLUGIN.includes(alert.plugin_id)


    useEffect(() => {
        if (silenceComment && silenceComment.length > 4){
            setEnabledSilenceButton(true)
        } else {
            setEnabledSilenceButton(false)
        }
    }, [silenceComment]);

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape' && isEnabledSilenceWindow) {
                setEnabledSilenceWindow(false)
            }
        }
        window.addEventListener('keydown', handleEscape)

        return () => {
            window.removeEventListener('keydown', handleEscape)
        }

    }, [isEnabledSilenceWindow]);

    useEffect(() => {
        if (alert?.customFields?.fixInstructions) {
            setFixInstruction(alert.customFields.fixInstructions)
        }
    },[alert])


    const recheckStatus = useMemo(() => {
        const matchedAlert = recheckedAlerts.find(entry => entry.alert_id === alert.alert_id)
        if (matchedAlert) {
            return matchedAlert.recheck_status === "poo" ? "poo" : "other"
        }
        return null
    }, [recheckedAlerts, alert.alert_id])


    useEffect(() => {
        switch (recheckStatus) {
            case "poo":
                setIsRefresh(false)
                setFailedRecheck(true)
                break
            case "other":
                setIsRefresh(true)
                setFailedRecheck(false)
                break
            default:
                setIsRefresh(false)
                setFailedRecheck(false)
        }

    }, [recheckStatus])

    useEffect(() => {
        const process = alert.comment.split(/\s+/).map(word => processAlertComment(word, user.usersCommentReplaceRules))
        setProcessedString(process)
    },[alert.comment])

    useEffect(() => {
        const comment = (
            <>
                {processedString ? processedString.map((element, index) => (
                    <React.Fragment key={index}>{element} </React.Fragment>
                )) : <></>}
            </>
        )
        setAlertComment(comment)
    },[processedString])



    const handleCheckboxChange = () => {
        if (selectedAlerts.includes(alert.alert_id)) {
            dispatch(deselectAlert(alert.alert_id))
        } else {
            dispatch(selectAlert(alert.alert_id))
        }
    }


    const ackButtonHint = alert.responsibleUser ? "unhandle alert" : "handle alert";
    const ackedAlert = {"alertId": alert.alert_id}
    const onAckHandle = async (ackedAlert) => {
        try {
            if (alert.responsibleUser === userEmail){
                 await AlertService.unack([ackedAlert])
            } else {
                 await AlertService.ack([ackedAlert])
            }
        }
        catch (e) {

            dispatch(setModalError("Oops. Something went wrong. Please, try a bit later"))
        }
    }

    const onRecheckHandle = async () => {
        try {
            await AlertService.refreshAlerts([["recheck", alert.alert_id]])
        }
        catch (e) {
            dispatch(openModal())
            dispatch(setModalError(e.response.data.name))
        }
    }


    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            if (isEnabledSilenceButton) {
                submitSilenceAlert()
            }
        }
    }

    let alertBackground
    let fontColor
    if(alert.silenced){
        fontColor = '#808080'
    } else {
        fontColor = 'black'
    }
    switch (alert.severity.toUpperCase()){
        case 'WARNING':
            alertBackground = '#FEFFC1'
            break
        case 'CRITICAL':
            alertBackground = '#FFBBBA'
            break
        case 'EMERGENCY':
            alertBackground = '#f67d7a'
            break
        case 'INFO':
            alertBackground = '#BED8FF'
            break
        case 'UNKNOWN':
            alertBackground = '#ffda9f'
            break
        default:
            alertBackground = '#bab8b8'
    }


    const handleSilenceButton = () => {
        setEnabledSilenceWindow(!isEnabledSilenceWindow)
    }

    const handleInfoButton = () => {
        searchParams.set("alert_id", alert.alert_id)
        setSearchParams(searchParams)
        dispatch(openModal())
    }

    const submitSilenceAlert = async () => {
        let endSilenceTime
        if (silenceDuration > 0) {
            endSilenceTime = Date.now() + Number(silenceDuration) * 60000
        } else {
            endSilenceTime = null
        }
        const silenceRule = {
            project: alert.project,
            host: alert.host,
            alertName: alert.alertName,
            startSilence: Date.now(),
            endSilence: endSilenceTime,
            comment: silenceComment
        }
        try {
            await AlertService.silence(silenceRule)
        }
        catch (e){
            dispatch(setModalError("Oops. Something went wrong. Please, try a bit later"))
        }

        setSilenceDuration(null)
        setSilenceComment(null)
        setEnabledSilenceWindow(false)
    }



    return (
        <div className={`${!isInspectMode ? styles.alertBody : styles.alertBody_small}`} key={alert.alert_id}
             style={{backgroundColor: alertBackground, color: fontColor}}>
            <div
                className={`${!isInspectMode ? styles.projectName : styles.projectName_small}`}>{alert.project[0].toUpperCase()}</div>
            <div className={`${!isInspectMode ? styles.host : styles.host_small}`}>
                <label>
                    <input
                        className={`${styles.selectCheckBox} ${!isInspectMode ? styles.selectCheckBox_scaled : null}`}
                    type="checkbox"
                        onChange={handleCheckboxChange}
                        checked={selectedAlerts.includes(alert.alert_id)}
                    />
                </label>
                <p className={styles.textFields}>{alert.host}</p>
            </div>
            <div className={`${!isInspectMode ? styles.severity : styles.severity_small}`}>
                <p className={styles.textFields}>{alert.severity.toUpperCase()}</p>
            </div>
            <button
                className={`${!isInspectMode ? styles.responsibleUser : styles.responsibleUser_small} ${commonStyles.buttonHint}`}
                style={{
                    backgroundImage: `url(${alert.responsibleUser
                        ? `https://gravatar.com/avatar/${sha256(alert.responsibleUser)}?s=150`
                        : process.env.PUBLIC_URL + "/images/user.svg"})`
                }}
                data-tooltip={ackButtonHint}
                onClick={e => {
                    e.preventDefault()
                    onAckHandle(ackedAlert)
                }}
                disabled={displayMode === HISTORY_DISPLAY || displayMode === SILENCED_DISPLAY}
            />
            <div className={`${!isInspectMode ? styles.alertName : styles.alertName_small}`}
            onClick={e => {
                e.preventDefault()
                handleInfoButton()
            }}
            >

                <Link to={`/main?alert_id=${alert.alert_id}`}
                      className={`${styles.textFields} ${styles.alertNameLink}`}>{alert.alertName}
                </Link>
            </div>
            <div
                className={`${!isInspectMode ? styles.alertTime : styles.alertTime_small}`}>{processTimeStamp(alert.fired)}</div>
            <div className={`${!isInspectMode ? styles.message : styles.message_small} ${SILENCED_DISPLAY && displayMode === SILENCED_DISPLAY ? styles.messageShort : null}`}>
                <p className={`${styles.textFields} ${displayMode === MAIN_DISPLAY && alert.silenced === true ? isInspectMode ? styles.textFieldsShare_small : styles.textFieldsShare : null}`}>{alert.msg}</p>
                {
                    displayMode === MAIN_DISPLAY && alert.silenced === true
                        ?
                        <p className={`${styles.textFields} ${isInspectMode ? styles.textFieldsShare_small : styles.textFieldsShare} `}>
                            <>
                                <span>Comment: </span>
                                {alertComment || alert.comment}
                            </>
                        </p>
                        :
                        null

                }
            </div>
            {
                displayMode === SILENCED_DISPLAY ?
                    <div className={`${styles.comment} ${isInspectMode ? styles.comment_small : null}`}>
                        <p className={styles.textFields}>
                            <>
                                <span>Comment: </span>
                                {alertComment || alert.comment}
                            </>
                        </p>
                    </div>
                    : null
            }

            {
                fixInstruction
                    ?
                    <a className={`${!isInspectMode ? styles.controlButton : styles.controlButton_small} ${styles.fixInstructions} ${commonStyles.buttonHint}`}
                       href={fixInstruction} target="_blank" rel="noopener noreferrer">
                    </a>
                    : <div className={`${!isInspectMode ? styles.controlButton : styles.controlButton_small}`}/>
            }
            <div
                className={`${styles.silenceWindow} ${isEnabledSilenceWindow ? styles.silenceWindowActive : styles.silenceWindowDisabled} ${!isInspectMode ? null : styles.silenceWindow_small}`}>
             <input type="text"
                       className={`${styles.silenceDuration} ${!isInspectMode ? null : styles.silenceDuration_small}`}
                       placeholder="duration mins."
                       id={`duration_${alert.alert_id}`}
                       ref={durationRef}
                       onKeyDown={handleKeyDown}
                       onChange={e => setSilenceDuration(e.target.value)}
                />
                <input type="text"
                       className={`${styles.silenceComment} ${!isInspectMode ? null : styles.silenceComment_small}`}
                       placeholder="comment"
                       id={`comment_${alert.alert_id}`}
                       ref={commentRef}
                       onKeyDown={handleKeyDown}
                       onChange={e => setSilenceComment(e.target.value)}
                />
                <button disabled={!isEnabledSilenceButton}
                        className={`${isEnabledSilenceButton ? styles.silenceButtonEnabled : styles.silenceButtonDisabled} ${styles.silenceButton} ${!isInspectMode ? null : styles.silenceButton_small}`}
                        onClick={(e) => {
                            e.preventDefault()
                            submitSilenceAlert()
                        }}
                >
                    Silence
                </button>
            </div>
            <button className={`${!isInspectMode ? styles.controlButton : styles.controlButton_small} 
                ${isRecheckableAlert
                ? `${failedRecheck ? styles.failedRefresh : styles.refresh} ${isRefresh ? commonStyles.rotatedIcon : commonStyles.buttonHint}`
                : ''}`}
                    data-tooltip="recheck alert"
                    onClick={event => {
                        event.preventDefault()
                        onRecheckHandle()
                    }}
                    disabled={isRefresh || !isRecheckableAlert}
            />
            {displayMode === MAIN_DISPLAY
                ? <button
                    className={`${!isInspectMode ? styles.controlButton : styles.controlButton_small} ${styles.silence} ${commonStyles.buttonHint}`}
                    data-tooltip="silence alert"
                    onClick={e => {
                        e.preventDefault()
                        handleSilenceButton()
                    }}
                />
                : null
            }
        </div>
    );
};

export default Alert;