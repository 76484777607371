import styles from './Header.module.css'
import commonStyles from '../../styles/common.module.css'
import {useDispatch, useSelector} from "react-redux";
import {
    openModal,
    setModalError,
} from "../../store/reducers/modalReducer";
import FilterMenu from "../FilterMenu/FilterMenu";
import {switchFilterMenu} from "../../store/reducers/hiddenMenuReducer";
import {setGroupingMenuValue, switchInspectMode} from "../../store/reducers/headerMenuReducer";
import AlertService from "../../services/AlertService";
import usePortalParam from "../../hooks/usePortalParam";
import {setFoundAlerts} from "../../store/reducers/alertReducer";
import React, {useEffect, useMemo, useState} from "react";
import {setOpenSilenceRules, showSilencedAlerts} from "../../store/reducers/silenceRulesReducer";
import {HISTORY_DISPLAY, MAIN_DISPLAY, SILENCED_DISPLAY} from "../../store/actions/DISPLAY_ACTIONS";
import {useSearchParams} from "react-router-dom";

const Header = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const isLogged = useSelector(state => state.authReducer.isLogged)
    const userEmail = useSelector(state => state.authReducer.user.userEmail)
    const userInfo = useSelector(state => state.authReducer.user)
    const displayMode = useSelector(state => state.setDisplay.display)
    const alerts = useSelector(state => state.webSocket.alerts)
    const historyAlerts = useSelector(state => state.setAlertReducer.historyAlerts)
    const totalAlertsNumbers = useSelector(state => state.setAlertReducer.totalAlertsNumber)
    const emergencyAlertsNumber = useSelector(state => state.setAlertReducer.emergencyAlertsNumber)
    const criticalAlertsNumber = useSelector(state => state.setAlertReducer.criticalAlertsNumber)
    const warningAlertsNumber = useSelector(state => state.setAlertReducer.warningAlertsNumber)
    const infoAlertsNumber = useSelector(state => state.setAlertReducer.infoAlertsNumber)
    const otherAlertsNumber = useSelector(state => state.setAlertReducer.otherAlertsNumber)
    const isInspectMode = useSelector(state => state.setHeaderMenuItemValue.inspectMode)
    const recheckedAlerts = useSelector(state => state.webSocket.recheckAlerts)
    const selectedAlerts = useSelector(state => state.setAlertReducer.selectedAlerts)
    const setPortalParams = usePortalParam()
    const isGrouped = useSelector(state => state.setHeaderMenuItemValue.grouping)
    const displaySilencedAlerts = useSelector(state => state.setSilenceRules.displaySilencedAlerts)


    const [isRecheck, setIsRecheck] = useState(false)
    const [searchPhrase, setSearchPhrase] = useState('')
    const [alertsToDisplay, setAlertsToDisplay] = useState([])
    const [isOpenCommentWindow, setIsOpenCommentWindow] = useState(false)
    const [comment, setComment] = useState("")
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [position, setPosition] = useState({
        x: (window.innerWidth - 500) / 2,
        y: (window.innerHeight - 600) / 2
    });

    const recheckStatuses = useMemo(() => {
        return recheckedAlerts.every(alert => alert.recheck_status === 'poo') ? 'poo' : 'other'
    }, [recheckedAlerts])

    useEffect(() => {
        switch (displayMode) {
            case SILENCED_DISPLAY:
                setAlertsToDisplay(alerts.filter(alert => alert.silenced === true))
                break
            case HISTORY_DISPLAY:
                setAlertsToDisplay(historyAlerts)
                break
            case MAIN_DISPLAY:
                if (displaySilencedAlerts) {
                    setAlertsToDisplay(alerts)
                }
                else {
                    setAlertsToDisplay(alerts.filter(alert => alert.silenced === false))
                }
                break
            default:
                setAlertsToDisplay(alerts.filter(alert => alert.silenced === false))
        }
    },[displayMode, alerts, historyAlerts, displaySilencedAlerts])

    useEffect(() => {
        if (searchPhrase.trim().length > 0) {
            const foundAlerts = [];
            alertsToDisplay.forEach((alert) => {
                for (const [_, value] of Object.entries(alert)) {
                    if (
                        typeof value === "string" &&
                        value.toLowerCase().includes(searchPhrase.trim().toLowerCase())
                    ) {
                        foundAlerts.push(alert);
                        break;
                    }
                }
            });
            dispatch(setFoundAlerts(foundAlerts));
        } else {
            dispatch(setFoundAlerts(null));
        }
    }, [searchPhrase, alertsToDisplay, dispatch])

    useEffect(() => {
        switch(recheckStatuses) {
            case 'poo':
                setIsRecheck(false)
                break
            case 'other':
                setIsRecheck(true)
                break
            default:
                setIsRecheck(false)
        }
    }, [recheckStatuses])

    useEffect(() => {
        const showParam = searchParams.get('show')
        if (showParam === 'silenced') {
            dispatch(showSilencedAlerts(true))
        }
    },[searchParams, dispatch])

    const onAvatarClick = (e) => {
        e.preventDefault()
        if (!isLogged){
            setPortalParams("login")
            dispatch(openModal())
        } else {
            setPortalParams("userInfo")
            dispatch(openModal())
        }

    }

    const searchReset = () => {
        dispatch(setFoundAlerts(null))
        setSearchPhrase('')
    }

    const onInspectClick = () => {
        dispatch(switchInspectMode())
    }
    const onFilterClick = () => {
        dispatch(switchFilterMenu())
    }

    const onGroupClick = () => {
        dispatch(setGroupingMenuValue())
    }

    const onSilenceClick =  async () => {
        dispatch(setOpenSilenceRules())
    }

    const onRecheckClick = async () => {
        if (selectedAlerts.length > 0) {
            const alertsToRecheck = []
            selectedAlerts.map(alert => {alertsToRecheck.push(["recheck", alert])})
            try {
                await AlertService.refreshAlerts(alertsToRecheck)
            }
            catch (e) {
                dispatch(openModal())
                dispatch(setModalError(e.response.data.name))
            }
        } else {
            try {
                await AlertService.refreshAlerts([["recheck_all", ""]])
            } catch (e) {
                dispatch(openModal())
                dispatch(setModalError(e.response.data.name))
            }
        }

    }

    const onSilenceAlertsClick = () => {
        if (displaySilencedAlerts) {
            dispatch(showSilencedAlerts(false))
            setSearchParams({ show: 'regular' })
        } else {
            dispatch(showSilencedAlerts(true))
            setSearchParams({ show: 'silenced' })
        }
    }

    const getSelectedAlerts = (alerts, selectedAlerts) => {
        return alerts.filter(alert => selectedAlerts.includes(alert.alert_id))
    }

    const onGroupAckClick = async () => {
        const selectedAlertsFull = getSelectedAlerts(alerts, selectedAlerts);

        if (selectedAlertsFull.every(alert => alert.responsibleUser === userEmail)) {
            const alertList = []
            selectedAlerts.map(alert => {
                alertList.push({alertId: alert})
            })

            try {
                await AlertService.unack(alertList)
            }
            catch (e) {
                dispatch(openModal())
                dispatch(setModalError(e.response.data.name))
            }

        } else {
            const alertList = []
            selectedAlerts.map(alert => {
                alertList.push({alertId: alert})
            })
            try {
                await AlertService.ack(alertList)
            } catch (e) {
                dispatch(openModal())
                dispatch(setModalError(e.response.data.name))
            }
        }
    };

    const onGroupCommentClick = () => {
        console.log("clicked grouip comment")
        setIsOpenCommentWindow(true)
    }

    const onCloseCommentClick = () => {
        setIsOpenCommentWindow(false)
    }

    const onMouseDown = (e) => {
        if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA" || e.target.tagName === "BUTTON") {
            return;
        }
        setIsDragging(true);
        setOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const onMouseMove = (e) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - offset.x,
                y: e.clientY - offset.y
            });
        }
    };

    const onMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);

        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };
    }, [isDragging]);

    const onCommentSendClick = async () => {
        const alertsToComment = []
        selectedAlerts.map(alert => {
            alertsToComment.push(alert)
        })
        const commentRequest = {
            alert_id: alertsToComment,
            comment: comment
        }
        try {
            await AlertService.postComment(commentRequest)
        }
        catch (e) {
            dispatch(openModal())
            dispatch(setModalError(e.response.data.name))
        } finally {
            setComment("")
            setIsOpenCommentWindow(false)
        }
    }

    return (
        <>
            <div className={styles.header}>
                <div className={styles.logo}></div>
                <div className={`${styles.onDutyPortal} ${isLogged ? styles.onDutyPortalActive : null}`}>
                    {isLogged ? " On call: Vasya Pupkin" : null}
                </div>
                <form className={styles.searchForm}>
                    <button id="alertSearchButton" type="submit" className={styles.searchButton}
                            disabled
                    />
                    <input id="alertSearchField" type="search" className={styles.searchField}
                           placeholder="Search..."
                           onChange={e => setSearchPhrase(e.target.value)}
                           onKeyDown={e => {
                               if (e.key === 'Escape') {
                                   searchReset()
                               }
                           }}
                    />
                    <button id="resetSearchButton"
                            type="reset"
                            className={`${styles.resetSearchButton} ${searchPhrase ? '' : styles.resetSearchButton_hidden}`}
                            onClick={e => searchReset()}
                    />
                </form>
                <div className={styles.alertsCount}
                     style={{borderColor: !isLogged ? "grey" : null}}
                >
                    {
                        isLogged
                            ?
                            <>

                                <p className={`${styles.alertCountItems} ${styles.alertsNumber} ${styles.emergencyAlerts} ${commonStyles.buttonHint}`}
                                   data-tooltip="emergency alerts"
                                >
                                    {emergencyAlertsNumber}
                                </p>
                                <p className={`${styles.alertCountItems} ${styles.alertsNumber} ${styles.criticalAlerts} ${commonStyles.buttonHint}`}
                                   data-tooltip="critical alerts"
                                >
                                    {criticalAlertsNumber}
                                </p>
                                <p className={`${styles.alertCountItems} ${styles.alertsNumber} ${styles.warningAlerts} ${commonStyles.buttonHint}`}
                                   data-tooltip="warning alerts"
                                >
                                    {warningAlertsNumber}
                                </p>
                                <p className={`${styles.alertCountItems} ${styles.alertsNumber} ${styles.infoAlerts} ${commonStyles.buttonHint}`}
                                   data-tooltip="info alerts"
                                >
                                    {infoAlertsNumber}
                                </p>
                                <p className={`${styles.alertCountItems} ${styles.alertsNumber} ${styles.otherAlerts} ${commonStyles.buttonHint}`}
                                   data-tooltip="other alerts"
                                >
                                    {otherAlertsNumber}
                                </p>
                                <p className={`${styles.alertCountItems} ${styles.alertsCountTitle}`}>TOTAL:</p>
                                <p className={`${styles.alertCountItems} ${styles.alertsNumber} ${styles.totalAlerts}`}>{totalAlertsNumbers}</p>
                            </>
                            : null
                    }

                </div>

                <button
                    className={`${styles.funcButton} ${styles.groupAlerts} ${isLogged ? styles.funcButtonEnabled : null} ${commonStyles.buttonHint}`}
                    style={isGrouped ? {backgroundColor: "#a0f1e2"} : {}}
                    disabled={!isLogged}
                    data-tooltip="group alerts"
                    onClick={(e) => {
                        e.preventDefault()
                        onGroupClick()
                    }}
                />

                <button
                    className={`${styles.funcButton} ${styles.inspectButton} ${isLogged ? styles.funcButtonEnabled : null} ${commonStyles.buttonHint}`}
                    style={isInspectMode ? {backgroundColor: "#a0f1e2"} : {}}
                    data-tooltip="inspect mode"
                    disabled={!isLogged}
                    onClick={(e) => {
                        e.preventDefault()
                        onInspectClick()
                    }}
                />

                <button
                    className={`${styles.funcButton} ${isLogged 
                        ? 
                        `${styles.funcButtonEnabled} 
                         ${styles.refreshButton} 
                         ${isRecheck ? commonStyles.rotatedIcon : commonStyles.buttonHint}`
                        : null}` }
                    data-tooltip="recheck all alerts"
                    onClick={e => {
                        e.preventDefault()
                        onRecheckClick()
                    }}
                    disabled={isRecheck}
                />

                <button
                    className={`${styles.funcButton} ${styles.silenceButton} ${isLogged ? styles.funcButtonEnabled : null} ${commonStyles.buttonHint}`}
                    style={displaySilencedAlerts ? {backgroundColor: "#a0f1e2", borderRadius: "5px"} : {}}
                    disabled={!isLogged}
                    data-tooltip="silenced alerts"
                    onClick={(e) => {
                        e.preventDefault()
                        onSilenceAlertsClick()
                    }}
                />
                <button
                    className={`${styles.funcButton} ${styles.silenceRulesButton} ${isLogged ? styles.funcButtonEnabled : null} ${commonStyles.buttonHint}`}
                    disabled={!isLogged}
                    data-tooltip="silence rules"
                    onClick={(e) => {
                        e.preventDefault()
                        onSilenceClick()
                    }}
                />
                <button
                    className={`${styles.funcButton} ${selectedAlerts.length > 0 ? styles.groupAckButton : styles.groupAckButton_disabled} ${isLogged ? styles.funcButtonEnabled : null} ${commonStyles.buttonHint}`}
                    disabled={!isLogged || selectedAlerts.length < 0}
                    data-tooltip="group ack"
                    onClick={(e) => {
                        e.preventDefault()
                        onGroupAckClick()
                    }}
                />
                <button
                    className={`${styles.funcButton} ${selectedAlerts.length > 0 ? styles.groupComment : styles.groupComment_disabled} ${isLogged ? styles.funcButtonEnabled : null} ${commonStyles.buttonHint}`}
                    disabled={!isLogged || selectedAlerts.length < 0}
                    data-tooltip="group comment"
                    onClick={(e) => {
                        e.preventDefault()
                        onGroupCommentClick()
                    }}
                />

                <button
                    className={`${styles.funcButton} ${styles.filterButton} ${isLogged ? styles.funcButtonEnabled : null} ${commonStyles.buttonHint}`}
                    data-tooltip="filters"
                    disabled={!isLogged}
                    onClick={e => {
                        e.preventDefault()
                        onFilterClick()
                    }}
                />
                <div className={`${styles.avatar} ${commonStyles.buttonHint}`}
                     style={{backgroundImage: `url(${isLogged ? userInfo.userImage : process.env.PUBLIC_URL + "/images/avatar.svg"})`}}
                     data-tooltip="userinfo"
                     onClick={e => {
                         onAvatarClick(e)
                     }}/>
            </div>
            <FilterMenu/>
            {
                isOpenCommentWindow
                ?
                    <div className={styles.commentWindow}
                    style={{
                        left: `${position.x}px`,
                        top: `${position.y}px`,
                        cursor: isDragging ? "grabbing" : "grab"
                    }}
                    onMouseDown={onMouseDown}
                    >
                        <button className={styles.closeCommentWindowButton}
                        onClick={e => {
                            e.preventDefault()
                            onCloseCommentClick()
                        }}
                        />
                        <h4 className={styles.commentWindowTitle}> Comment to selected alerts</h4>
                        <form className={styles.commentForm}>
                            <textarea className={styles.commentTextArea}
                            onChange={e => setComment(e.target.value)}
                            />
                            <button type="submit" className={`${styles.sendCommentButton} ${comment.trim().length < 5 ? styles.sendCommentButton_disabled : null}`}
                                    disabled={comment.trim().length < 5}
                                    onClick={e => {
                                        e.preventDefault()
                                        onCommentSendClick()
                                    }}
                            >
                                Comment
                            </button>
                        </form>


                    </div>
                : null
            }
        </>
    )
}

export default Header;