import io from "socket.io-client"
import {BACKEND_SERVER} from "../utils/vars"

class SocketApiService {
    static socket = null;
    static createConnection(token) {
        this.socket = io(`${BACKEND_SERVER}`,{
            extraHeaders:{
                'Authorization': `Bearer ${token}`
            },
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
        }
        )
    }
    static closeConnection() {
        if(this.socket){
            this.socket.close()
        }
    }

}

export default SocketApiService