import styles from './MainPage.module.css'
import Header from "../Header/Header";
import SideBarMenu from "../SideBarMenu/SideBarMenu";
import {useDispatch, useSelector} from "react-redux";
import {switchSideBarState} from "../../store/reducers/hiddenMenuReducer";
import {switchActiveHeaderMenuItem} from "../../store/reducers/headerMenuReducer";
import MainDisplay from "../MainDisplay/MainDisplay";
import Modal from "../Modal/Modal";
import {useEffect} from "react";
import {openModal} from "../../store/reducers/modalReducer";
import usePortalParam from "../../hooks/usePortalParam";
import {useSearchParams} from "react-router-dom";
import {
    ESCALATIONS_DISPLAY,
    HISTORY_DISPLAY,
    MAIN_DISPLAY,
    SILENCED_DISPLAY,
    SILENCED_RULES_DISPLAY,
    STATS_DISPLAY
} from "../../store/actions/DISPLAY_ACTIONS";
import StatPage from "../StatPage/StatPage";
import Escalations from "../Escalations/Escalations";
import SilenceRules from "../SIlenceRules/SilenceRules";
import SilenceModal from "../SilenceModal/SilenceModal";
import {switchDisplayMode} from "../../store/reducers/displayModeReducer";

function MainPage({displayMode}){

    const dispatch = useDispatch()
    const isOpenedSideBar = useSelector(state => state.hiddenMenu.isOpenedSideBar)
    const modalContent = useSelector(state => state.switchModal.content)
    const isLoggedIn = useSelector(state => state.authReducer.isLogged)
    const [searchParams, setSearchParams] = useSearchParams();
    const setPortalParams = usePortalParam()
    const alertParam = searchParams.get("alert_id")

    const onSideBarClick = () => {
        dispatch(switchSideBarState())
    }
    const closeHeaderMenu = () => {
        dispatch(switchActiveHeaderMenuItem(null))
    }

    useEffect(() => {
        if (!isLoggedIn){
            dispatch(openModal())
            setPortalParams("login");
        }
        else {
            setPortalParams()
            if(alertParam){
                dispatch(openModal())
            }
        }
    }, [dispatch, isLoggedIn, alertParam]);

    useEffect(() => {
        dispatch(switchDisplayMode(displayMode))
    }, [displayMode])

    return (
        <div className={styles.page}>
            <header className={styles.header}>
                <Header displayMode={displayMode}/>
            </header>
            <div className={styles.mainWindow} onClick={closeHeaderMenu}>
                <SideBarMenu/>
                <div className={styles.sideBar}>
                    <button className={isOpenedSideBar ? `${styles.menuArrow} ${styles.menuArrowOpened}` : `${styles.menuArrow} ${styles.menuArrowClosed}`}
                            disabled={!isLoggedIn}
                            onClick={e => {
                            e.preventDefault()
                            onSideBarClick()
                        }}
                    ></button>
                </div>
                {isLoggedIn && displayMode === STATS_DISPLAY && <StatPage />}
                {isLoggedIn && displayMode === ESCALATIONS_DISPLAY && <Escalations />}
                {isLoggedIn && (displayMode === MAIN_DISPLAY || displayMode === SILENCED_DISPLAY || displayMode === HISTORY_DISPLAY) && <MainDisplay displayMode={displayMode} />}
                {isLoggedIn && displayMode === SILENCED_RULES_DISPLAY && <SilenceRules />}

            </div>
            <Modal content={modalContent}/>
            <SilenceModal/>
        </div>
    )
}

export default MainPage;

