import {
    DETAILED_ALERT,
    RECHECK_ALL_ALERTS,
    SET_TOTAL_ALERTS_NUMBER,
    SET_FOUND_ALERTS,
    SET_CRITICAL_ALERTS_NUMBER,
    SET_WARNING_ALERTS_NUMBER,
    SET_OTHER_ALERTS_NUMBER,
    SET_EMERGENCY_ALERTS_NUMBER,
    SET_INFO_ALERTS_NUMBER,
    SET_HISTORY_ALERTS, SELECT_ALERT, DESELECT_ALERT
} from "../actions/ALERT_ACTIONS";


const defaultdAlert = {
    totalAlertsNumber: 0,
    emergencyAlertsNumber: 0,
    criticalAlertsNumber: 0,
    warningAlertsNumber: 0,
    infoAlertsNumber: 0,
    otherAlertsNumber: 0,
    foundAlerts: null,
    historyAlerts: [],
    selectedAlerts: [],
    recheckAllAlerts: false,
}

export const alertReducer = (state = defaultdAlert, action) => {
    switch (action.type) {
        case SET_TOTAL_ALERTS_NUMBER:
            return {...state, totalAlertsNumber: action.payload}
        case SET_EMERGENCY_ALERTS_NUMBER:
            return {...state, emergencyAlertsNumber: action.payload}
        case SET_CRITICAL_ALERTS_NUMBER:
            return {...state, criticalAlertsNumber: action.payload}
        case SET_WARNING_ALERTS_NUMBER:
            return {...state, warningAlertsNumber: action.payload}
        case SET_INFO_ALERTS_NUMBER:
            return {...state, infoAlertsNumber: action.payload}
        case SET_OTHER_ALERTS_NUMBER:
            return {...state, otherAlertsNumber: action.payload}
        case SET_FOUND_ALERTS:
            return {...state, foundAlerts: action.payload}
        case SET_HISTORY_ALERTS:
            return {...state, historyAlerts: action.payload}
        case RECHECK_ALL_ALERTS:
            return {...state, recheckAllAlerts: action.payload}
        case SELECT_ALERT:
            return {...state, selectedAlerts: [...state.selectedAlerts, action.payload]}
        case DESELECT_ALERT:
            return {...state, selectedAlerts: state.selectedAlerts.filter(alert => alert !== action.payload) }
        default:
            return state
    }
}

export const setTotalAlertsNumber = (number) => ({
    type: SET_TOTAL_ALERTS_NUMBER,
    payload: number
})

export const setWarningAlertsNumber = (number) => ({
    type: SET_WARNING_ALERTS_NUMBER,
    payload: number
})

export const setEmergencyAlertsNumber = (number) => ({
    type: SET_EMERGENCY_ALERTS_NUMBER,
    payload: number
})

export const setCriticalAlertsNumber = (number) => ({
    type: SET_CRITICAL_ALERTS_NUMBER,
    payload: number
})

export const setInfoAlertsNumber = (number) => ({
    type: SET_INFO_ALERTS_NUMBER,
    payload: number
})

export const setOtherAlertsNumber = (number) => ({
    type: SET_OTHER_ALERTS_NUMBER,
    payload: number
})

export const setFoundAlerts = (alerts) => ({
    type: SET_FOUND_ALERTS,
    payload: alerts
})

export const setHistoryAlerts = (alerts) => ({
    type: SET_HISTORY_ALERTS,
    payload: alerts
})

export const recheckAllAlerts = (state) => ({
    type: RECHECK_ALL_ALERTS,
    payload: state
})

export const selectAlert = (alert) => ({
    type: SELECT_ALERT,
    payload: alert,
})

export const deselectAlert = (state) => ({
    type: DESELECT_ALERT,
    payload: state
})
