export const DETAILED_ALERT = 'DETAILED_ALERT'
export const SET_TOTAL_ALERTS_NUMBER = 'SET_TOTAL_ALERTS_NUMBER'
export const SET_EMERGENCY_ALERTS_NUMBER = 'SET_EMERGENCY_ALERTS_NUMBER'
export const SET_CRITICAL_ALERTS_NUMBER = 'SET_CRITICAL_ALERTS_NUMBER'
export const SET_WARNING_ALERTS_NUMBER = 'SET_WARNING_ALERTS_NUMBER'
export const SET_INFO_ALERTS_NUMBER = 'SET_INFO_ALERTS_NUMBER'
export const SET_OTHER_ALERTS_NUMBER = 'SET_OTHER_ALERTS_NUMBER'
export const SET_FOUND_ALERTS = 'SET_ALERTS_TO_DISPLAY'
export const SET_HISTORY_ALERTS = 'SET_HISTORY_ALERTS'
export const RECHECK_ALL_ALERTS = 'RECHECK_ALL_ALERTS'
export const SELECT_ALERT = 'SELECT_ALERT'
export const DESELECT_ALERT = 'DESELECT_ALERT'